import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Structure/Layout'
import MatrixBuilder from '../components/MatrixBuilder/MatrixBuilder'
import SEO from '../components/seo'

class IndexPage extends Component {
	render() {
		const {
			data: {
				craft: { entry }
			}
		} = this.props

		return (
			<Layout location={this.props.location}>
				<SEO
					title={'This is Pensa'}
					keywords={[`pensa`, `design`, `product design`]}
				/>
				<MatrixBuilder matrixBuilder={entry.matrixBuilder} />
			</Layout>
		)
	}
}

export default IndexPage

export const IndexPageQuery = graphql`
	query IndexQuery {
		craft {
			entry(section: home) {
				... on Craft_Home {
					title
					matrixBuilder {
						...MatrixBuilderQuery
					}
				}
			}
		}
	}
`
